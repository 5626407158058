<ng-container *ngIf="form">
    <form-email *ngIf="hasEmail" [form]="form.get('email.0.value')" [language]="language"></form-email>
    <div class="row">
        <div class="col-12 col-sm-4">
            <form-floating>
                <input
                    class="form-control"
                    [formControl]="form.get('name.first')"
                    type="text"
                    placeholder="{{ language.contactDetails.firstName.place }}"
                    data-cy="nameFirst"
                    autocomplete="given-name"
                    input-validation
                />
                <label>{{ language.contactDetails.firstName.label }}</label>
            </form-floating>
        </div>
        <div class="col-12 col-sm-4">
            <form-floating>
                <label>{{ language.contactDetails.middleName.label }}</label>
                <input
                    class="form-control"
                    [formControl]="form.get('name.middle')"
                    type="text"
                    placeholder="{{ language.contactDetails.middleName.place }}"
                    data-cy="nameMiddle"
                    autocomplete="additional-name"
                    input-validation
                />
            </form-floating>
        </div>
        <div class="col-12 col-sm-4">
            <form-floating>
                <label>{{ language.contactDetails.lastName.label }}</label>
                <input
                    class="form-control"
                    [formControl]="form.get('name.last')"
                    type="text"
                    placeholder="{{ language.contactDetails.lastName.place }}"
                    data-cy="nameLast"
                    autocomplete="family-name"
                    input-validation
                />
            </form-floating>
        </div>
    </div>

    <form-floating>
        <label>{{ language.contactDetails.phoneNumber.label }}</label>
        <input
            [formControl]="form.get('mobile.0.value')"
            class="form-control"
            type="tel"
            placeholder="{{ language.contactDetails.phoneNumber.place }}"
            data-cy="mobile"
            autocomplete="tel-national"
            input-validation
        />
        <span help-text>
            {{ language.contactDetails.phoneNumber.helpText }}
        </span>
        <div class="invalid-feedback">
            {{ language.contactDetails.phoneNumber.errorText }}
        </div>
    </form-floating>

    <form-floating>
        <label>{{ language.contactDetails.communicationPreference.label }}</label>
        <select [formControl]="form.get('communicationPreference')" class="form-select">
            <option [ngValue]="null">{{ language.contactDetails.communicationPreference.none }}</option>
            <option value="email">{{ language.contactDetails.communicationPreference.email }}</option>
            <option value="phone">{{ language.contactDetails.communicationPreference.phone }}</option>
        </select>
    </form-floating>
    <div class="row mb-3" *ngIf="formType !== 'call'">
        <div class="col-4">
            <label>{{ language.contactDetails.companyQuestion.label }}</label>
        </div>
        <div class="col-8">
            <div class="form-check form-switch">
                <input [(ngModel)]="isCompanyRemoval" type="checkbox" class="form-check-input" data-cy="companyQuestion" />
                <label class="form-check-label text-muted">{{ language.contactDetails.companyQuestion.helpText }}</label>
            </div>
        </div>
    </div>

    <ng-container *ngIf="isCompanyRemoval">
        <form-floating>
            <label>{{ language.m3Info.accountManager.place }}</label>
            <input
                class="form-control"
                [formControl]="form.get('accountManager.name')"
                type="text"
                placeholder="{{ language.m3Info.accountManager.place }}"
                data-cy="accountManager"
                autocomplete="off"
                input-validation
            />
        </form-floating>

        <div class="row">
            <div class="col-12 col-sm-6">
                <form-floating>
                    <label>{{ language.contactDetails.companyName.place }}</label>
                    <input
                        class="form-control"
                        [formControl]="form.get('company.name')"
                        type="text"
                        placeholder="{{ language.contactDetails.companyName.place }}"
                        data-cy="nameCompany"
                        autocomplete="organization"
                        input-validation
                    />
                </form-floating>
            </div>
            <div class="col-12 col-sm-6">
                <form-floating>
                    <label>{{ language.contactDetails.kvk?.label || 'kvk' }}</label>
                    <input
                        class="form-control"
                        [formControl]="form.get('company.kvk')"
                        type="text"
                        placeholder="{{ language.contactDetails.kvk?.place }}"
                        data-cy="kvkCompany"
                        autocomplete="off"
                        input-validation
                    />
                </form-floating>
            </div>
        </div>
    </ng-container>
</ng-container>
