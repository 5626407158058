import { Component, EventEmitter, forwardRef, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core'
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms'
import dayjs from 'dayjs'
import en from 'dayjs/locale/en'
import nl from 'dayjs/locale/nl'
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore'
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'
import { NgIf, NgFor, NgClass, PercentPipe } from '@angular/common'
import { FormFloatingComponent } from '../form/form-floating/form-floating.component'

dayjs.extend(isSameOrAfter)
dayjs.extend(isSameOrBefore)

@Component({
    selector: 'date-picker',
    templateUrl: './date-picker.component.html',
    styleUrls: [`./date-picker.component.scss`],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgIf, FontAwesomeModule, NgFor, NgClass, FormsModule, PercentPipe, FormFloatingComponent],
})
export class DatePickerComponent implements ControlValueAccessor, OnChanges {
    // Events
    @Output() dateChange: EventEmitter<any> = new EventEmitter()

    @Input() language = 'nl' // ['nl' 'en']
    @Input() type = 'inline' // ['popover' 'dropdown', 'inline']

    @Input() time = false
    @Input() timeDescription
    @Input() timePrecision = 15
    @Input() timeRangeFrom = 0
    @Input() timeRangeTo = 24

    @Input() date = true
    @Input() datePlaceholder
    @Input() dateDescription
    @Input() dateDisplayFormat = 'DD-MM-YYYY (dddd)'
    @Input() dateInit = new Date()
    @Input() dateFrom: number | Date = new Date('1980-01-01 00:00')
    @Input() dateTo: number | Date = new Date('2040-12-31 23:59')
    @Input() dateRangeHighlight

    @Input() dateConfig
    @Input() daysDisabled = []

    // Receives an array with warnings
    // - title
    // - message
    // - style [warning, error, success]
    // - type ['range', 'in-past', 'weekend', 'sunday']
    // - (params) [startRange, endRange]
    @Input() dateWarning = []
    get dateWarningLength() {
        return this.dateWarning.filter((w) => w.show).length
    }

    mobile

    daysOfWeek = {
        nl: [
            { id: 'ma', name: { full: 'ma' } },
            { id: 'di', name: { full: 'di' } },
            { id: 'wo', name: { full: 'wo' } },
            { id: 'do', name: { full: 'do' } },
            { id: 'vr', name: { full: 'vr' } },
            { id: 'za', name: { full: 'za' }, weekend: true },
            { id: 'zo', name: { full: 'zo' }, weekend: true },
        ],
        en: [
            { id: 'zo', name: { full: 'Su' }, weekend: true },
            { id: 'ma', name: { full: 'Mo' } },
            { id: 'di', name: { full: 'Tu' } },
            { id: 'wo', name: { full: 'We' } },
            { id: 'do', name: { full: 'Th' } },
            { id: 'vr', name: { full: 'Fr' } },
            { id: 'za', name: { full: 'Sa' }, weekend: true },
        ],
    }

    d = {
        today: this.momentGet(),
        moment: null,

        selected: {
            millisecond: null,
            second: null,
            minute: null,
            hour: null,
            date: null,
            month: null,
            year: null,

            time: null,
            zone: 'Europe/Amsterdam',
        },

        times: [],
        daysOfWeek: this.daysOfWeek[this.language],
        days: [
            { show: true, value: '01', label: 1 },
            { show: true, value: '02', label: 2 },
            { show: true, value: '03', label: 3 },
            { show: true, value: '04', label: 4 },
            { show: true, value: '05', label: 5 },
            { show: true, value: '06', label: 6 },
            { show: true, value: '07', label: 7 },
            { show: true, value: '08', label: 8 },
            { show: true, value: '09', label: 9 },
            { show: true, value: '10', label: 10 },
            { show: true, value: '11', label: 11 },
            { show: true, value: '12', label: 12 },
            { show: true, value: '13', label: 13 },
            { show: true, value: '14', label: 14 },
            { show: true, value: '15', label: 15 },
            { show: true, value: '16', label: 16 },
            { show: true, value: '17', label: 17 },
            { show: true, value: '18', label: 18 },
            { show: true, value: '19', label: 19 },
            { show: true, value: '20', label: 20 },
            { show: true, value: '21', label: 21 },
            { show: true, value: '22', label: 22 },
            { show: true, value: '23', label: 23 },
            { show: true, value: '24', label: 24 },
            { show: true, value: '25', label: 25 },
            { show: true, value: '26', label: 26 },
            { show: true, value: '27', label: 27 },
            { show: true, value: '28', label: 28 },
            { show: true, value: '29', label: 29 },
            { show: true, value: '30', label: 30 },
            { show: true, value: '31', label: 31 },
        ],
        months: [
            { show: true, value: '01', label: 'januari' },
            { show: true, value: '02', label: 'februari' },
            { show: true, value: '03', label: 'maart' },
            { show: true, value: '04', label: 'april' },
            { show: true, value: '05', label: 'mei' },
            { show: true, value: '06', label: 'juni' },
            { show: true, value: '07', label: 'juli' },
            { show: true, value: '08', label: 'augustus' },
            { show: true, value: '09', label: 'september' },
            { show: true, value: '10', label: 'oktober' },
            { show: true, value: '11', label: 'november' },
            { show: true, value: '12', label: 'december' },
        ],
        years: [
            // {
            // 	show: true,
            // 	value: '1970',
            // 	label: '1970'
            // },
        ],
        calendar: {
            days: [],
            view: {
                // Current moment we are viewing
                moment: null,

                // Label that is shown on top of view
                label: null,

                // Maximum range the calendar is allowed to have
                from: null,
                to: null,

                // Settings considering month view
                month: {
                    previous: null,
                    next: null,
                },
            },
        },
    }

    ngOnChanges(simpleChanges: SimpleChanges) {
        const dateRangeHighlight = simpleChanges.dateRangeHighlight
        if (dateRangeHighlight && !dateRangeHighlight.isFirstChange()) this.calendarRenderDates()
    }

    // this is the initial value set to the component
    public writeValue(exactmoment) {
        // If no moment provided
        if (exactmoment && exactmoment.unix) {
            // Update moment
            this.onDateChange('x', exactmoment.unix)
        }

        // Set language
        this.calendarSetLocale()

        // Check device type
        this.mobileAndTabletCheck()

        // Init dates
        this.initDates()

        // Render calendar
        this.calendarInit()
    }

    public validate() {
        return this.d.moment?.valueOf() ? null : { isDateEmpty: true }
    }

    // registers 'fn' that will be fired when changes are made this is how we emit the changes back to the form
    public registerOnChange(fn: any) {
        this.propagateChange = fn
    }

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    public registerOnTouched() {}
    // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
    private propagateChange = (_: unknown) => {}

    private doPropagateChange() {
        const value = {
            unix: this.d.moment.valueOf(),
        }

        // Emit function
        this.dateChange.emit(value)

        // Propagate form change
        this.propagateChange(value)
    }

    onInputValue(unit: string, target: EventTarget) {
        this.onDateChange(unit, (target as HTMLSelectElement).value)
    }

    // Trigger propagateChange
    onDateChange(unit, value) {
        const dMoment = this.d.moment || dayjs().startOf('day').set('hour', 9)
        if (unit === 'YYYY-MM-DD') {
            const split = value.split('-')
            this.d.moment = dMoment.set('year', split[0]).set('month', split[1]).subtract(1, 'month').set('date', split[2])
        } else if (unit === 'HH:mm') {
            const split = value.split(':')
            this.d.moment = dMoment.set('hour', split[0]).set('minute', split[1])
        } else if (unit === 'x') {
            this.d.moment = dayjs(value)
        } else if (unit === 'year') this.d.moment = dMoment.set('year', value)
        else if (unit === 'month') this.d.moment = dMoment.set('month', value).subtract(1, 'month')
        else if (unit === 'date') this.d.moment = dMoment.set('date', value)
        else if (unit === 'hour') this.d.moment = dMoment.set('hour', value)
        else if (unit === 'second') this.d.moment = dMoment.set('second', value)
        else if (unit === 'millisecond') this.d.moment = dMoment.set('millisecond', value)

        // Check how many days this month has
        const daysInMonth = this.d.moment.daysInMonth()
        this.d.days.forEach((day, index) => {
            day.show = index < daysInMonth
        })

        // Update date selected
        this.updateDateSelected()

        // Check if the set date triggers any warnings
        this.checkWarnings()

        // update the form
        this.doPropagateChange()
    }

    private checkWarnings() {
        this.dateWarning.forEach((warning) => {
            const start = this.momentGet(this.d.moment).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
            const end = this.momentGet(this.d.today).set('hour', 0).set('minute', 0).set('second', 0).set('millisecond', 0)
            const diff = start.diff(end, 'day', true)

            if (warning.type === 'range' && warning.params[0] <= diff && diff <= warning.params[1]) warning.show = true
            else if (warning.type === 'in-past' && diff < 0) warning.show = true
            else if (warning.type === 'in-past-or-today' && diff <= 0) warning.show = true
            else if (warning.type === 'today' && diff === 0) warning.show = true
            else if (warning.type === 'weekend') null
            else if (warning.type === 'sunday') null
            else warning.show = false

            // Receives an array with warnings
            // - title
            // - message
            // - style [warning, error, success]
            // - type ['range', 'in-past', 'weekend', 'sunday']
            // - (params) [startRange, endRange]
        })
    }

    private momentGet(i = null) {
        const m = dayjs(i).set('hour', 13)
        return m
    }

    private initDates() {
        let yearFrom = parseInt(this.momentGet(this.dateFrom).format('YYYY'))
        const yearTo = parseInt(this.momentGet(this.dateTo).format('YYYY'))

        // Create years
        while (yearFrom++ <= yearTo) {
            this.d.years.push({
                show: true,
                value: `${yearFrom - 1}`,
                label: `${yearFrom - 1}`,
            })
        }

        // Create times
        for (let i = this.timeRangeFrom; i < this.timeRangeTo; i++) {
            for (let j = 0; j < 60; j = j + this.timePrecision) {
                const label = `${i > 9 ? '' : '0'}${i}:${j > 9 ? '' : '0'}${j}`
                this.d.times.push({
                    show: true,
                    label: label,
                    value: label,
                })
            }
        }
    }

    // Calendar life cycle
    //
    onCalendarDateChange(day) {
        const date = day.value

        // Check if date is allowed
        if (day['date-disabled'] || day['date-range-before'] || day['date-range-after']) return

        // Update the value
        this.onDateChange('YYYY-MM-DD', date)

        // Get old and new month
        const viewMonthCurrent = this.d.calendar.view.moment.format('MM')
        const viewMonthNew = date.substring(5, 7)

        // If month changed re-render full calendar
        if (viewMonthCurrent !== viewMonthNew) return this.onCalendarViewChange('month', date)

        // Only update selected if same month
        this.d.calendar.days.forEach((week) => {
            week.forEach((day) => {
                // Unselect currently selected date
                if (day.selected) day.selected = false

                // Select clicked value
                if (day.value === date) day.selected = true
            })
        })
    }

    onCalendarViewChange(view, value) {
        const calView = this.d.calendar.view

        // Go to default view
        if (value === 0) calView.moment = this.momentGet(this.dateInit)
        // Move view one unit forward or backward
        else if (value === 1 || value === -1) calView.moment = calView.moment.add(value, view)
        // Move view to specific date
        else calView.moment = this.momentGet(value)

        // Check if the next or previous buttons have to be disabled
        calView.month.next = this.momentGet(calView.moment).endOf('month').add(1, 'day').isBefore(calView.to)
        calView.month.previous = this.momentGet(calView.moment).startOf('month').add(-1, 'day').isAfter(calView.from)

        // Rerender the view
        this.calendarRenderDates()
    }

    private calendarInit() {
        const calView = this.d.calendar.view

        // On init we collect the range in which calendar can operate
        calView.from = this.momentGet(this.dateFrom).startOf('day')
        calView.to = this.momentGet(this.dateTo).endOf('day')

        // We go to the correct month, this is determined using the selected/init moment
        this.onCalendarViewChange('M', 0)
    }

    private calendarRenderDates() {
        const calView = this.d.calendar.view

        // The current month we are looking at
        const calMonth = calView.moment.format('MM')

        // Value that is looped over, may start month ago
        let calMoment = this.momentGet(calView.moment).startOf('month').startOf('week')

        // Set empty calendar
        this.d.calendar.days = [[], [], [], [], [], [], []]

        // Update label
        calView.label = calView.moment.format('MMMM YYYY')

        // Create 42 units
        for (let i = 0; i < 42; i++) {
            const dayIso = calMoment.format('YYYY-MM-DD')
            const dayConfig = this.dateConfig ? this.dateConfig[dayIso] : null // Math.round(Math.random() * 10) / 100;
            const dayConfigDiscount = dayConfig ? dayConfig.discount : 0

            // console.log({
            //     d: dayConfig !== undefined,
            //     dayConfig: this.dayConfig,
            // });

            const day = {
                label: calMoment.format('D'), // Holds the date number

                'month-previous': calMoment.format('MM') < calMonth,
                'month-current': calMoment.format('MM') === calMonth,
                'month-next': calMoment.format('MM') > calMonth,

                'date-disabled': this.daysDisabled.includes(calMoment.day()),
                'date-range-before': calMoment.isBefore(calView.from),
                'date-range-after': calMoment.isAfter(calView.to),

                value: dayIso,
                past: calMoment.isBefore(this.d.today),
                selected: calMoment.isSame(this.d.moment, 'day'),
                today: calMoment.isSame(this.d.today, 'day'),

                'date-discount-active': this.dateConfig !== undefined,
                'date-discount': dayConfigDiscount,
                'date-discount-low': dayConfigDiscount === 0,
                'date-discount-medium': dayConfigDiscount >= 0.01,
                'date-discount-high': dayConfigDiscount > 0.05,
            }

            const hasDateRangeHighlight = this.dateRangeHighlight
            if (hasDateRangeHighlight) {
                const dateRangeHighlightFrom = this.momentGet(this.dateRangeHighlight[0])
                const dateRangeHighlightTo = this.momentGet(this.dateRangeHighlight[1])
                day['date-range-highlight'] =
                    calMoment.isSameOrAfter(dateRangeHighlightFrom, 'day') && calMoment.isSameOrBefore(dateRangeHighlightTo, 'day')
                day['date-range-highlight-start'] = calMoment.isSame(dateRangeHighlightFrom, 'day')
                day['date-range-highlight-end'] = calMoment.isSame(dateRangeHighlightTo, 'day')
            }

            // Add
            this.d.calendar.days[Math.floor(i / 7)].push(day)

            // Go to next day
            calMoment = calMoment.add(1, 'day')
        }
    }

    private calendarSetLocale() {
        if (this.language === 'en') this.d.daysOfWeek = this.daysOfWeek.en

        if (this.language === 'nl') dayjs.locale(nl)
        else dayjs.locale(en)
    }

    private updateDateSelected() {
        this.d.selected = {
            millisecond: this.d.moment.format('SSS'),
            second: this.d.moment.format('ss'),
            minute: this.d.moment.format('mm'),
            hour: this.d.moment.format('HH'),
            date: this.d.moment.format('DD'),
            month: this.d.moment.format('MM'),
            year: this.d.moment.format('YYYY'),

            time: this.d.moment.format('HH:mm'),
            zone: 'Europe/Amsterdam',
        }
    }

    private mobileAndTabletCheck() {
        this.mobile = false
    }
}
