<div class="row">
    <div class="col-12">
        <div class="card card-body">
            <h4 class="card-title">{{ language.moveDetails.moveDate.label.certain }}</h4>

            <form-floating class="mt-2">
                <label>{{ language.moveDetails.startCertainty.label }}</label>
                <select class="form-control" [formControl]="formEstimateStartCertainty" required>
                    <option value="" disabled>{{ language.moveDetails.startCertainty.choose }}</option>
                    <option value="certain">{{ language.moveDetails.startCertainty.certain }}</option>
                    <!-- <option value="flexible">{{ language.moveDetails.startCertainty.flexible }}</option> -->
                    <option value="estimate">{{ language.moveDetails.startCertainty.estimate }}</option>
                    <option value="unknown">{{ language.moveDetails.startCertainty.unknown }}</option>
                </select>
            </form-floating>

            <form-floating *ngIf="formEstimate.get('startCertainty').value === 'estimate'">
                <label>{{ language.moveDetails.startCertaintyDates?.label }}</label>
                <input
                    type="text"
                    placeholder="DD-MM-YYYY - DD-MM-YYYY"
                    class="form-control"
                    bsDaterangepicker
                    [formControl]="formEstimate.get('startCertaintyDates')"
                    [bsConfig]="bsDatepickerConfig"
                    input-validation
                />
                <span help-text>
                    {{ language.moveDetails.startCertaintyDates?.helpText }}
                </span>
            </form-floating>

            <ng-container
                *ngIf="
                    formEstimateStartCertainty.valid &&
                    (formEstimate.get('startCertainty').value !== 'estimate' || formEstimate.get('startCertaintyDates').valid) &&
                    formEstimate.get('startCertainty').value !== 'unknown'
                "
            >
                <label>{{ language.moveDetails.date.label }}</label>
                <date-picker
                    *ngIf="dateConfig || !this.shouldShowDiscount"
                    [formControl]="formEstimateStart"
                    [dateRangeHighlight]="formEstimate.get('startCertaintyDates').value"
                    [language]="language.id"
                    [type]="'inline'"
                    [time]="true"
                    [timeDescription]="language.moveDetails.moveTime.label"
                    [timePrecision]="30"
                    [timeRangeFrom]="9"
                    [timeRangeTo]="20"
                    [dateConfig]="dateConfig"
                    [dateInit]="dateInit"
                    [dateFrom]="dateFrom"
                    [dateTo]="dateTo"
                    [dateWarning]="[
                        {
                            title: language.date.errors.past.title,
                            message: language.date.errors.past.message,
                            type: 'in-past',
                            style: 'danger'
                        },
                        {
                            title: language.date.errors.emergency.title,
                            message: language.date.errors.emergency.message,
                            type: 'range',
                            params: [0, 3],
                            style: 'warning'
                        },
                        {
                            title: language.date.errors.farAway.title,
                            message: language.date.errors.farAway.message,
                            type: 'range',
                            params: [365, 999999],
                            style: 'warning'
                        }
                    ]"
                ></date-picker>

                <div class="row">
                    <div class="offset-md-4 col-md-8">
                        <div class="alert alert-success" *ngIf="getCurrentDateConfig().discount">
                            {{ language.moveDetails.date.discountPre }}
                            {{ getCurrentDateConfig().discount | percent }}
                            {{ language.moveDetails.date.discountAfter }}
                        </div>

                        <!-- <div class="alert alert-danger" *ngIf="getCurrentDateConfig().addition">
                            {{ language.moveDetails.date.additionPre }}
                            {{ getCurrentDateConfig().addition | percent }}
                            {{ language.moveDetails.date.additionAfter }}
                        </div> -->
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</div>
