<fieldset *ngIf="form">
    <form-floating>
        <label>{{ language.addressDetails.country.label }}</label>
        <select [formControl]="form.get('country')" (change)="addressFromPostal()" class="form-select" data-cy="country" input-validation>
            <option value="nl">{{ language.addressDetails.country.netherlands }}</option>
            <option value="be">{{ language.addressDetails.country.belgium }}</option>
            <option value="lu">{{ language.addressDetails.country.luxembourg }}</option>
            <option value="de">{{ language.addressDetails.country.germany }}</option>
            <option value="dk">{{ language.addressDetails.country.denmark }}</option>
            <option value="uk">{{ language.addressDetails.country.unitedKingdom }}</option>
            <option value="fr">{{ language.addressDetails.country.france }}</option>
            <option value="es">{{ language.addressDetails.country.spain }}</option>
            <option value="it">{{ language.addressDetails.country.italy }}</option>
            <option value="se">{{ language.addressDetails.country.sweden }}</option>
            <option value="pt">{{ language.addressDetails.country.portugal }}</option>
        </select>
    </form-floating>

    <div class="row">
        <div class="col-12 col-sm-6">
            <form-floating>
                <label>
                    {{ language.addressDetails.postal.label }}
                </label>
                <input
                    [formControl]="form.get('postal')"
                    type="text"
                    class="form-control"
                    placeholder="{{ language.addressDetails.postal.place }}"
                    (blur)="addressFromPostal()"
                    data-cy="postal"
                    input-validation
                />

                <ng-container *ngIf="form.get('city').disabled">
                    <button *ngIf="postalLoading" class="btn btn-outline-secondary">
                        <fa-icon [icon]="['fal', 'spinner-third']" [fixedWidth]="true" [spin]="true"></fa-icon>
                    </button>
                    <button
                        *ngIf="!postalLoading"
                        class="btn btn-outline-info"
                        (click)="postalUserDontKnow(); $event.stopPropagation()"
                        tippy
                        [tippyOptions]="{ content: language.addressDetails.postal.unknown }"
                    >
                        <fa-icon [icon]="['fal', 'question-circle']" [fixedWidth]="true"></fa-icon>
                    </button>
                </ng-container>
            </form-floating>
        </div>
        <div class="col-12 col-sm-3">
            <form-floating>
                <label>{{ language.addressDetails.streetNumber.label }}</label>
                <input
                    [formControl]="form.get('number')"
                    class="form-control"
                    type="text"
                    placeholder="{{ language.addressDetails.streetNumber.place }}"
                    (blur)="addressFromPostal()"
                    data-cy="number"
                    input-validation
                />
            </form-floating>
        </div>
        <div class="col-12 col-sm-3">
            <form-floating>
                <label>{{ language.addressDetails.streetAddition.place }}</label>
                <input
                    [formControl]="form.get('addition')"
                    class="form-control"
                    type="text"
                    placeholder="{{ language.addressDetails.streetAddition.place }}"
                    data-cy="addition"
                    input-validation
                />
            </form-floating>
        </div>
    </div>

    <div class="row">
        <div class="col-12 col-sm-6">
            <form-floating>
                <label>{{ language.addressDetails.city.label }}</label>
                <input
                    [formControl]="form.get('city')"
                    class="form-control"
                    type="text"
                    placeholder="{{ language.addressDetails.city.place }}"
                    data-cy="city"
                    input-validation
                />
            </form-floating>
        </div>
        <div class="col-12 col-sm-6">
            <form-floating>
                <label>{{ language.addressDetails.streetName.label }}</label>
                <input
                    [formControl]="form.get('street')"
                    class="form-control"
                    type="text"
                    placeholder="{{ language.addressDetails.streetName.place }}"
                    data-cy="street"
                    input-validation
                />
            </form-floating>
        </div>
    </div>

    <div class="form-group row" *ngIf="message.notFound">
        <div class="col-12">
            <div class="alert alert-warning">
                <h5 class="alert-heading">{{ language.addressDetails.postal.error?.title }}</h5>
                <p class="mb-0">{{ language.addressDetails.postal.error?.body }}</p>
            </div>
        </div>
    </div>

    <ng-container *ngIf="hasRemovalInfo">
        <h5 class="card-title mt-2">
            {{ language.addressDetails.label.info }}
        </h5>

        <form-floating>
            <label>{{ language.addressDetails.floor.label }}</label>
            <select
                [formControl]="form.get('floor')"
                class="form-select"
                data-cy="floor"
                (input)="form.get('moveType').patchValue('stairs')"
                input-validation
            >
                <option [ngValue]="null" selected disabled>{{ language.addressDetails.floor.select }}</option>
                <option *ngFor="let option of optionsFloor" [ngValue]="option.value">{{ option.label }}</option>
            </select>
        </form-floating>

        <div class="mb-3" *ngIf="form.value.floor > 0">
            <div class="form-check form-switch">
                <input
                    [formControl]="form.get('liftInternal')"
                    type="checkbox"
                    class="form-check-input"
                    id="liftInternal"
                    (change)="setMoveType()"
                />
                <div class="input-group input-group-sm d-flex align-items-center">
                    <label class="form-check-label text-muted" for="liftInternal" data-cy="liftInternal">
                        {{ language.addressDetails.moveMethod.question.liftInternal }}
                    </label>
                    <span class="ms-2">
                        <information-icon [className]="'text-info'" [size]="'sm'" (click)="$event.stopPropagation()">
                            {{ language.addressDetails.moveMethod.infoBody.liftInternal }}
                        </information-icon>
                    </span>
                </div>
            </div>
        </div>

        <form-floating>
            <label>{{ language.addressDetails.workDescription.label }}</label>
            <textarea
                class="form-control"
                [style.height]="'200px'"
                [formControl]="form.get('description')"
                placeholder="{{ language.addressDetails.workDescription.place }}"
                input-validation
                autocomplete="off"
            ></textarea>
            <div help-text>
                {{ language.addressDetails.workDescription.place }}
            </div>
        </form-floating>
    </ng-container>
</fieldset>
